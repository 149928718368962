*,
.scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: theme('colors.body-color');
    }

    &::-webkit-scrollbar-thumb {
        background-color: theme('colors.primary');
        border-radius: 10px;
    }
}

body {
    user-select: none;
}

.scrollbar {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.split-lines,
.split-words,
.split-chars {
    will-change: transform;
    backface-visibility: hidden;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
    border-color: theme('colors.primary') !important;
}

.carousel__button.is-close {
    right: unset !important;
    top: 0 !important;
    left: 50%;
    background-color: transparent;
    width: 60px !important;
    height: 60px !important;
    border-radius: 100% !important;
    transition: .35s ease all;
    transform: translateX(-50%) translateY(-50%) !important;
    background-color: #6C727D !important;

    svg {
        filter: unset !important;
        color: white !important;
        stroke-width: 3 !important;
        transition: .6s cubic-bezier(0.22, 0.68, 0, 1.1);
    }

    &:hover {
        background-color: theme('colors.primary') !important;

        svg {
            transform: rotate(-90deg);
            color: theme('colors.primary')important;
        }
    }
}

.cookie-bar {
    margin: 0 0 0 auto;

    &.hide-cookie {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
    }

    .cookie-content {
        .text-field .cookie-icon path {
            fill: theme('colors.primary')
        }

        .button-field .accept:hover .icon {
            transform: translateX(3px);
        }
    }
}

.animate-carousel {
    .swiper-slide {
        &>* {
            opacity: 0;
            pointer-events: none;
            transform: scale(0.8);
            transition: .6s cubic-bezier(.25, .75, .5, 1.25);
        }

        &-visible {
            &>* {
                opacity: 1;
                pointer-events: visible;
                transform: scale(1);
            }
        }
    }
}

.iti {
    display: block;
}

.iti input {
    padding-left: 90px !important;
}

.iti__flag {
    background-image: url("../image/source/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../image/source/flags@2x.png");
    }
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
    width: 90px;
    padding: 0;
}

.iti__selected-flag {
    transition: .35s ease all;
    margin: 0;
    padding: 0;
    justify-content: center;
    gap: 10px;
}

.iti__arrow {
    border: unset !important;
    position: relative;
    width: 12px;
    height: 12px;
    margin-left: 0;
}

.iti__arrow::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMi4wMiA2LjM0Ij48cGF0aCBkPSJtNiw2LjM0Yy0uMTQsMC0uMjgtLjA1LS4zOS0uMTZMLjE2LjljLS4yMS0uMjEtLjIxLS41NCwwLS43NS4yMS0uMjEuNTYtLjIxLjc3LDBsNS4wNyw0LjkxTDExLjA5LjE3Yy4yMS0uMjEuNTYtLjIxLjc3LDAsLjIxLjIxLjIxLjU0LDAsLjc1bC01LjQ3LDUuMjdjLS4xMS4xLS4yNS4xNS0uMzkuMTVaIi8+PC9zdmc+');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: invert(1);
}

.iti__country-list {
    background-color: var(--color-body-color);
    border: 1px solid var(--color-lynch-800);
    color: white;
    z-index: 50;
}

.iti__divider {
    border-bottom: 1px solid var(--color-lynch-800);

}


.tippy-box[data-theme~='penta'] {
    background-color: theme('colors.primary');
    color: white;
    font-size: 12px;
}

.tippy-box[data-theme~='penta'][data-placement^='top']>.tippy-arrow::before {
    border-top-color: theme('colors.primary');
}

.tippy-box[data-theme~='penta'][data-placement^='bottom']>.tippy-arrow::before {
    border-bottom-color: theme('colors.primary');
}

.tippy-box[data-theme~='penta'][data-placement^='left']>.tippy-arrow::before {
    border-left-color: theme('colors.primary');
}

.tippy-box[data-theme~='penta'][data-placement^='right']>.tippy-arrow::before {
    border-right-color: theme('colors.primary');
}

.fancybox__backdrop {
    background: rgba(21, 36, 49, 0.85)
}

.dynamic-sticky {
    position: sticky;
    top: 45px;
}

.sticky-center {
    position: sticky;
    top: 0;
}

@-webkit-keyframes moveMouse {

    0%,
    to {
        -webkit-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }

    50% {
        -webkit-transform: translateY(10%) scale(.5);
        transform: translateY(10%) scale(.5);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }
}

@-moz-keyframes moveMouse {

    0%,
    to {
        -moz-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
        -moz-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }

    50% {
        -moz-transform: translateY(10%) scale(.5);
        transform: translateY(10%) scale(.5);
        -moz-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }
}

@keyframes moveMouse {

    0%,
    to {
        -webkit-transform: translateY(0) scale(1);
        -moz-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }

    50% {
        -webkit-transform: translateY(10%) scale(.5);
        -moz-transform: translateY(10%) scale(.5);
        transform: translateY(10%) scale(.5);
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }
}

.animate-moveMouse {
    -webkit-animation: moveMouse 2s infinite forwards;
    -moz-animation: moveMouse 2s infinite forwards;
    animation: moveMouse 2s infinite forwards
}