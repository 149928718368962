/* [------------------] Plugins [------------------] */
@import '../../node_modules/swiper/swiper-bundle.min.css';
@import '../../node_modules/@fancyapps/ui/dist/fancybox.css';
@import 'intl-tel-input/build/css/intlTelInput.css';
@import '../../node_modules/tippy.js/dist/tippy.css';

// Base 
@import 'base/functions';
@import 'base/mixin';
@import 'base/fonts';
@import 'base/penta-logo';
@import 'base/reset';

// Views
@import 'views/extra';