@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.eot');
    src: local('Plus Jakarta Sans ExtraBold Italic'), local('PlusJakartaSans-ExtraBoldItalic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBoldItalic.svg#PlusJakartaSans-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-Bold.eot');
    src: local('Plus Jakarta Sans Bold'), local('PlusJakartaSans-Bold'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Bold.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Bold.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Bold.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Bold.svg#PlusJakartaSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBold.eot');
    src: local('Plus Jakarta Sans ExtraBold'), local('PlusJakartaSans-ExtraBold'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBold.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBold.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBold.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraBold.svg#PlusJakartaSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-BoldItalic.eot');
    src: local('Plus Jakarta Sans Bold Italic'), local('PlusJakartaSans-BoldItalic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-BoldItalic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-BoldItalic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-BoldItalic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-BoldItalic.svg#PlusJakartaSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-Regular.eot');
    src: local('Plus Jakarta Sans Regular'), local('PlusJakartaSans-Regular'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Regular.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Regular.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Regular.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Regular.svg#PlusJakartaSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-Italic.eot');
    src: local('Plus Jakarta Sans Italic'), local('PlusJakartaSans-Italic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Italic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Italic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Italic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Italic.svg#PlusJakartaSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLight.eot');
    src: local('Plus Jakarta Sans ExtraLight'), local('PlusJakartaSans-ExtraLight'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLight.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLight.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLight.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLight.svg#PlusJakartaSans-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-Medium.eot');
    src: local('Plus Jakarta Sans Medium'), local('PlusJakartaSans-Medium'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Medium.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Medium.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Medium.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Medium.svg#PlusJakartaSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.eot');
    src: local('Plus Jakarta Sans ExtraLight Italic'), local('PlusJakartaSans-ExtraLightItalic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-ExtraLightItalic.svg#PlusJakartaSans-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-Light.eot');
    src: local('Plus Jakarta Sans Light'), local('PlusJakartaSans-Light'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Light.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Light.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Light.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-Light.svg#PlusJakartaSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.eot');
    src: local('Plus Jakarta Sans SemiBold Italic'), local('PlusJakartaSans-SemiBoldItalic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBoldItalic.svg#PlusJakartaSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBold.eot');
    src: local('Plus Jakarta Sans SemiBold'), local('PlusJakartaSans-SemiBold'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBold.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBold.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBold.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-SemiBold.svg#PlusJakartaSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-LightItalic.eot');
    src: local('Plus Jakarta Sans Light Italic'), local('PlusJakartaSans-LightItalic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-LightItalic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-LightItalic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-LightItalic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-LightItalic.svg#PlusJakartaSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../font/plus-jakarta-sans/PlusJakartaSans-MediumItalic.eot');
    src: local('Plus Jakarta Sans Medium Italic'), local('PlusJakartaSans-MediumItalic'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-MediumItalic.woff2') format('woff2'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-MediumItalic.woff') format('woff'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-MediumItalic.ttf') format('truetype'),
        url('../font/plus-jakarta-sans/PlusJakartaSans-MediumItalic.svg#PlusJakartaSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'iconfont';
    src: url('../font/iconfont/iconfont.eot');
    src: url('../font/iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
        url('../font/iconfont/iconfont.woff') format('woff'),
        url('../font/iconfont/iconfont.ttf') format('truetype'),
        url('../font/iconfont/iconfont.svg#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'iconfont';
    src: url('../font/iconfont/iconfont.eot');
    src: url('../font/iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
        url('../font/iconfont/iconfont.woff') format('woff'),
        url('../font/iconfont/iconfont.ttf') format('truetype'),
        url('../font/iconfont/iconfont.svg#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*='icon-']:before {
    display: inline-block;
    font-family: 'iconfont';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-arrow-left:before {
    content: '\0041';
}

.icon-arrow-long-bold-right:before {
    content: '\0042';
}

.icon-arrow-right:before {
    content: '\0043';
}

.icon-author:before {
    content: '\0044';
}

.icon-chevron-bottom:before {
    content: '\0045';
}

.icon-chevron-left:before {
    content: '\0046';
}

.icon-chevron-right:before {
    content: '\0047';
}

.icon-chevron-top:before {
    content: '\0048';
}

.icon-circles:before {
    content: '\0049';
}

.icon-copy:before {
    content: '\004a';
}

.icon-download:before {
    content: '\004b';
}

.icon-downvote:before {
    content: '\004c';
}

.icon-envelope:before {
    content: '\004d';
}

.icon-facebook:before {
    content: '\004e';
}

.icon-fullscreen:before {
    content: '\004f';
}

.icon-instagram:before {
    content: '\0050';
}

.icon-like:before {
    content: '\0051';
}

.icon-lines:before {
    content: '\0052';
}

.icon-link:before {
    content: '\0053';
}

.icon-linkedin:before {
    content: '\0054';
}

.icon-menu-phone:before {
    content: '\0055';
}

.icon-menu-quote:before {
    content: '\0056';
}

.icon-pause:before {
    content: '\0057';
}

.icon-phone:before {
    content: '\0058';
}

.icon-pinterest:before {
    content: '\0059';
}

.icon-play:before {
    content: '\005a';
}

.icon-plus:before {
    content: '\0061';
}

.icon-resize:before {
    content: '\0062';
}

.icon-search:before {
    content: '\0063';
}

.icon-sound-off:before {
    content: '\0064';
}

.icon-sound-on:before {
    content: '\0065';
}

.icon-star:before {
    content: '\0066';
}

.icon-start:before {
    content: '\0067';
}

.icon-time:before {
    content: '\0068';
}

.icon-twitter:before {
    content: '\0069';
}

.icon-upvote:before {
    content: '\006a';
}

.icon-whatsapp:before {
    content: '\006b';
}